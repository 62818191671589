import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"

const SectionPodcastsHostedByNew = () => {
  const data = useStaticQuery(graphql`
    query SectionPodcastsHostedByNewQuery {
      imgHost: file(relativePath: { eq: "podcasts/sections/van-carlson-photo.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
    }
  `)

  const imgHost = getImage(data.imgHost)

  return (    
    <div className="bg-white">
      <div className="relative w-full pt-8 pb-8 md:pt-16 md:pb-14">
        <div className='w-11/12 max-w-screen-xl flex flex-col lg:flex-row items-center justify-center mx-auto space-x-0 lg:space-x-16 p-2'>
          <div className="w-full xl:w-2/3">
            <div className="z-20 flex flex-col items-center justify-center lg:items-start lg:justify-start">
              <p className='text-gray-700 text-lg md:text-3xl text-center lg:text-left uppercase mb-2 md:mb-4 tracking-widest'>Hosted By</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold uppercase tracking-wider mb-8">Van Carlson</h2>
              <p className='text-gray-700 text-1xl md:text-2xl text-center lg:text-left mb-8'>Van Carlson is the Founder & CEO at SRA and has over twenty five years of experience within the risk management industry. Van began his career with Farmers Insurance Group as an agent; eventually growing his book to be among the largest in his home state of Idaho.</p>

              <div className='hidden lg:block w-full xl:w-8/12'>
                <Link to="/team">
                  <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3 mb-8 xl:mb-0">MORE ABOUT SRA 831(b) ADMIN</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full xl:w-1/3 flex flex-col items-center justify-center">
            <GatsbyImage
              alt="Van Carlson photo"
              image={imgHost}
              formats={["auto", "webp", "avif"]}
              className="z-20"
            />
          </div>

          <div className='block lg:hidden w-full md:w-8/12 mt-8'>
            <Link to="/team">
              <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white tracking-wider px-10 md:px-16 py-3">MORE ABOUT SRA 831(b) ADMIN</button>
            </Link>
          </div>
        </div>

        <div className="absolute hidden lg:block bottom-0 left-0 z-0">
          <StaticImage
            src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
            width={490}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>

        <div className="absolute block lg:hidden top-8 left-0 z-0">
          <StaticImage
            src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
            width={490}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt=""
          />
        </div>
      </div> 
    </div>    
  )
}

export default SectionPodcastsHostedByNew