import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components'

import LayoutNew from "../components/layout-new"
import Seo from "../components/seo"
import HeroSectionPodcasts from "../components/sections/hero-section-podcasts"
import HeroSectionPodcastsMobileNew from "../components/sections/hero-section-podcasts-mobile-new";
import SectionPodcastsGetStartedMobile from "../components/sections/section-podcasts-get-started-mobile";
import SectionPodcastsGetStarted from "../components/sections/section-podcasts-get-started";
import PortalPodcastUpdate from "../components/common/portal-podcast-update";
import SectionPodcastsHostedByNew from "../components/sections/section-podcasts-hosted-by-new";
import SectionPodcastsRequestNew from "../components/sections/section-podcasts-request-new";

const StyledDiv = styled.div`
  .react-tabs {
    text-align: center;
  }
  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #757575;
  }
  .react-tabs__tab {
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 2px;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    margin-right: 2px;
    margin-bottom: 2px;
  }
  .react-tabs__tab--selected {
    border-radius: 0;
    border: 1px solid #bb0000;
    background: #bb0000;
    color: #ffffff;
  }  
  .react-tabs__tab:hover {
    background: #cf1716;
    color: #ffffff;
  }
  .react-tabs__tab-panel {
    padding: 24px 0;
    background: transparent;
  }

  @media (max-width: 767px) {
    .react-tabs__tab {
      font-size: 13px;
      padding-top: 5px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 8px;
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
`

const PagePodcastsNew = () => {
  const data = useStaticQuery(graphql`
    query PagePodcastsNewQueryNew {
      prismicPodcastsPage {
        _previewable
        data {
          podcasts_page_title
          podcasts_page_short_description
          podcasts_section {
            title
            about
            category_medical_dental
            category_manufacturing
            category_entrepreneurship
            category_finance_taxes
            category_professional_services
            category_construction
            category_real_estate
            category_agriculture
            category_insurance
            audio_url
            share_link {
              link_type
              uid
              url
            }
            podcast_image {
              alt
              gatsbyImageData(
                width: 300
                height: 300
              )
            }
            status
          }
        }
      }
      imgHero: file(relativePath: { eq: "podcasts/backgrounds/hero-bg-new-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
      imgIcon1: file(relativePath: { eq: "podcasts/icons/icon-1-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
      imgIcon2: file(relativePath: { eq: "podcasts/icons/icon-2-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
      imgIcon3: file(relativePath: { eq: "podcasts/icons/icon-3-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
      imgIcon4: file(relativePath: { eq: "podcasts/icons/icon-4-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
      imgIcon5: file(relativePath: { eq: "podcasts/icons/icon-5-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 800
          )
        }
      }
      imgCard: file(relativePath: { eq: "podcasts/sections/unmitigated-podcast-card.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 383
          )
        }
      }
    }
  `)

  const doc = data.prismicPodcastsPage
  const imgHero = getImage(data.imgHero)
  const imgIcon1 = getImage(data.imgIcon1)
  const imgIcon2 = getImage(data.imgIcon2)
  const imgIcon3 = getImage(data.imgIcon3)
  const imgIcon4 = getImage(data.imgIcon4)
  const imgIcon5 = getImage(data.imgIcon5)
  const imgSrc = getSrc(data.imgHero)

  // Filter 'New' podcasts to show at the top
  const podcastsWithTrueStatus = doc.data.podcasts_section.filter(podcast => podcast.status === true);
  const podcastsWithFalseStatus = doc.data.podcasts_section.filter(podcast => podcast.status === false);

  const podcastsWithTrueStatusAndCatMedDent = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_medical_dental);
  const podcastsWithFalseStatusAndCatMedDent = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_medical_dental);

  const podcastsWithTrueStatusAndCatManu = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_manufacturing);
  const podcastsWithFalseStatusAndCatManu = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_manufacturing);

  const podcastsWithTrueStatusAndCatEntre = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_entrepreneurship);
  const podcastsWithFalseStatusAndCatEntre = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_entrepreneurship);

  const podcastsWithTrueStatusAndCatFinTax = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_finance_taxes);
  const podcastsWithFalseStatusAndCatFinTax = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_finance_taxes);

  const podcastsWithTrueStatusAndCatProSer = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_professional_services);
  const podcastsWithFalseStatusAndCatProSer = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_professional_services);

  const podcastsWithTrueStatusAndCatConst = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_construction);
  const podcastsWithFalseStatusAndCatConst = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_construction);

  const podcastsWithTrueStatusAndCatReEs = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_real_estate);
  const podcastsWithFalseStatusAndCatReEs = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_real_estate);

  const podcastsWithTrueStatusAndCatAgri = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_agriculture);
  const podcastsWithFalseStatusAndCatAgri = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_agriculture);

  const podcastsWithTrueStatusAndCatInsur = doc.data.podcasts_section.filter(podcast => podcast.status === true && podcast.category_insurance);
  const podcastsWithFalseStatusAndCatInsur = doc.data.podcasts_section.filter(podcast => podcast.status === false && podcast.category_insurance);

  podcastsWithTrueStatus.sort().reverse();
  podcastsWithTrueStatusAndCatMedDent.sort().reverse();
  podcastsWithTrueStatusAndCatManu.sort().reverse();
  podcastsWithTrueStatusAndCatEntre.sort().reverse();
  podcastsWithTrueStatusAndCatFinTax.sort().reverse();
  podcastsWithTrueStatusAndCatProSer.sort().reverse();
  podcastsWithTrueStatusAndCatConst.sort().reverse();
  podcastsWithTrueStatusAndCatReEs.sort().reverse();
  podcastsWithTrueStatusAndCatAgri.sort().reverse();
  podcastsWithTrueStatusAndCatInsur.sort().reverse();

  // Concatenate the arrays with true status item on top
  const podcasts = [...podcastsWithTrueStatus, ...podcastsWithFalseStatus];
  const podcastsWithCatMedDent = [...podcastsWithTrueStatusAndCatMedDent, ...podcastsWithFalseStatusAndCatMedDent];
  const podcastsWithCatManu = [...podcastsWithTrueStatusAndCatManu, ...podcastsWithFalseStatusAndCatManu];
  const podcastsWithCatEntre = [...podcastsWithTrueStatusAndCatEntre, ...podcastsWithFalseStatusAndCatEntre];
  const podcastsWithCatFinTax = [...podcastsWithTrueStatusAndCatFinTax, ...podcastsWithFalseStatusAndCatFinTax];
  const podcastsWithCatProSer = [...podcastsWithTrueStatusAndCatProSer, ...podcastsWithFalseStatusAndCatProSer];
  const podcastsWithCatConst = [...podcastsWithTrueStatusAndCatConst, ...podcastsWithFalseStatusAndCatConst];
  const podcastsWithCatReEs = [...podcastsWithTrueStatusAndCatReEs, ...podcastsWithFalseStatusAndCatReEs];
  const podcastsWithCatAgri = [...podcastsWithTrueStatusAndCatAgri, ...podcastsWithFalseStatusAndCatAgri];
  const podcastsWithCatInsur = [...podcastsWithTrueStatusAndCatInsur, ...podcastsWithFalseStatusAndCatInsur];

  const [displayedPodcasts, setDisplayedPodcasts] = React.useState(4);
  const [displayedPodcastsWithCatMedDent, setDisplayedPodcastsWithCatMedDent] = React.useState(4);
  const [displayedPodcastsWithCatManu, setDisplayedPodcastsWithCatManu] = React.useState(4);
  const [displayedPodcastsWithCatEntre, setDisplayedPodcastsWithCatEntre] = React.useState(4);
  const [displayedPodcastsWithCatFinTax, setDisplayedPodcastsWithCatFinTax] = React.useState(4);
  const [displayedPodcastsWithCatProSer, setDisplayedPodcastsWithCatProSer] = React.useState(4);
  const [displayedPodcastsWithCatConst, setDisplayedPodcastsWithCatConst] = React.useState(4);
  const [displayedPodcastsWithCatReEs, setDisplayedPodcastsWithCatReEs] = React.useState(4);
  const [displayedPodcastsWithCatAgri, setDisplayedPodcastsWithCatAgri] = React.useState(4);
  const [displayedPodcastsWithCatInsur, setDisplayedPodcastsWithCatInsur] = React.useState(4);

  const loadMore = () => {
    setDisplayedPodcasts(prev => prev + 4);
  };

  const loadMoreWithCatMedDent = () => {
    setDisplayedPodcastsWithCatMedDent(prev => prev + 4);
  };

  const loadMoreWithCatManu = () => {
    setDisplayedPodcastsWithCatManu(prev => prev + 4);
  };

  const loadMoreWithCatEntre = () => {
    setDisplayedPodcastsWithCatEntre(prev => prev + 4);
  };

  const loadMoreWithCatFinTax = () => {
    setDisplayedPodcastsWithCatFinTax(prev => prev + 4);
  };

  const loadMoreWithCatProSer = () => {
    setDisplayedPodcastsWithCatProSer(prev => prev + 4);
  };

  const loadMoreWithCatConst = () => {
    setDisplayedPodcastsWithCatConst(prev => prev + 4);
  };

  const loadMoreWithCatReEs = () => {
    setDisplayedPodcastsWithCatReEs(prev => prev + 4);
  };

  const loadMoreWithCatAgri = () => {
    setDisplayedPodcastsWithCatAgri(prev => prev + 4);
  };

  const loadMoreWithCatInsur = () => {
    setDisplayedPodcastsWithCatInsur(prev => prev + 4);
  };

  return (
    <LayoutNew>
      <Seo
        title={doc.data.podcasts_page_title}
        description={doc.data.testimonials_page_short_description}
        image={imgSrc}
        noIndex
      />

      <HeroSectionPodcasts
        introTitle="Welcome To"
        title="Unmitigated"
        subTitle="Bringing to light the hidden risks business owners and entrepreneurs take every day"
        iconsSectionTitle="Subscribe and Listen:"
        imgSrc={imgHero}
        imgIcon1Src={imgIcon1}
        imgIcon2Src={imgIcon2}
        imgIcon3Src={imgIcon3}
        imgIcon4Src={imgIcon4}
        imgIcon5Src={imgIcon5}
      />

      <HeroSectionPodcastsMobileNew
        introTitle="Welcome To"
        title="Unmitigated"
        subTitle="Bringing to light the hidden risks business owners and entrepreneurs take every day"
        iconsSectionTitle="Subscribe and Listen:"
        imgSrc={imgHero}
        imgIcon1Src={imgIcon1}
        imgIcon2Src={imgIcon2}
        imgIcon3Src={imgIcon3}
        imgIcon4Src={imgIcon4}
        imgIcon5Src={imgIcon5}
      />

      <SectionPodcastsGetStarted />
      <SectionPodcastsGetStartedMobile />

      <div className="bg-site-gray-new pt-12 md:pt-16 pb-8 md:pb-12">
        <div className="w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
          <p className='text-gray-100 text-lg md:text-3xl text-center uppercase mb-2 tracking-widest'>Catch Up On Our</p>
          <h2 className="text-white text-2xl md:text-5xl text-center font-extrabold uppercase tracking-wider">Featured Podcasts</h2>
        </div>

        <StyledDiv className="w-11/12 max-w-screen-xl mt-8 mx-auto">
          <Tabs>
            <div className="w-full max-w-screen-lg mx-auto">
              <TabList>
                <Tab>All</Tab>
                <Tab>Medical & Dental</Tab>
                <Tab>Manufacturing</Tab>
                <Tab>Entrepreneurship</Tab>
                <Tab>Finance & Taxes</Tab>
                <Tab>Professional Services</Tab>
                <Tab>Construction</Tab>
                <Tab>Real Estate</Tab>
                <Tab>Agriculture</Tab>
                <Tab>Insurance</Tab>
              </TabList>
            </div>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcasts.slice(0, displayedPodcasts).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                  )
                })}
              </div>
              {displayedPodcasts < podcasts.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatMedDent.slice(0, displayedPodcastsWithCatMedDent).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_medical_dental
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatMedDent < podcastsWithCatMedDent.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatMedDent}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatManu.slice(0, displayedPodcastsWithCatManu).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_manufacturing
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatManu < podcastsWithCatManu.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatManu}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatEntre.slice(0, displayedPodcastsWithCatEntre).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_entrepreneurship
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatEntre < podcastsWithCatEntre.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatEntre}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatFinTax.slice(0, displayedPodcastsWithCatFinTax).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_finance_taxes
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatFinTax < podcastsWithCatFinTax.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatFinTax}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatProSer.slice(0, displayedPodcastsWithCatProSer).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_professional_services
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatProSer < podcastsWithCatProSer.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatProSer}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatConst.slice(0, displayedPodcastsWithCatConst).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_construction
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatConst < podcastsWithCatConst.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatConst}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatReEs.slice(0, displayedPodcastsWithCatReEs).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_real_estate
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatReEs < podcastsWithCatReEs.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatReEs}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatAgri.slice(0, displayedPodcastsWithCatAgri).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_agriculture
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatAgri < podcastsWithCatAgri.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatAgri}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>

            <TabPanel>
              <div className="relative w-full grid grid-cols-1 lg:grid-cols-4 gap-6 mx-auto p-2">          
                {podcastsWithCatInsur.slice(0, displayedPodcastsWithCatInsur).map(item => {
                  const podcastImg = getImage(item.podcast_image)

                  return (
                    item.category_insurance
                    ?
                    <PortalPodcastUpdate
                      key={item.title}  // Use a unique key for each podcast item
                      podcastImg={podcastImg}
                      podcastTitle={item.title}
                      podcastText={item.about}
                      audioUrl={item.audio_url}
                      buttonLinkType={item.share_link.link_type}
                      buttonLinkUid={item.share_link.uid}
                      buttonLinkUrl={item.share_link.url}
                    />
                    :
                    null
                  )
                })}
              </div>
              {displayedPodcastsWithCatInsur < podcastsWithCatInsur.length && (
                <div className="flex justify-center mt-4">
                  <button
                    className="w-10/12 md:w-64 bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3 mt-4 lg:mt-0"
                    onClick={loadMoreWithCatInsur}
                  >
                    Load More
                  </button>
                </div>
              )}
            </TabPanel>
          </Tabs>
        </StyledDiv>        
      </div>

      <SectionPodcastsHostedByNew />

      <SectionPodcastsRequestNew />
    </LayoutNew>
  )
}

export default withPrismicPreview(PagePodcastsNew)
